import { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { LoaderAnimation } from '@/components/Molecules';
import { Layout } from '@/components/Organisms';
import { UserMenuContainer } from '@/components/Templates';

import {
  ActionListPage,
  CarbonPage,
  CarbonReportDetailPage,
  CarbonUploadPage,
  FamilyDetailsPage,
  IFramePage,
  KPIUpdatePage,
  LearningPlaygroundPage,
  LoginPage,
  QuestionDetailsPage,
  QuizQuestionPage,
  QuizResultPage,
  ResultDetailsPage,
  SustainableITPage,
  WIPPage,
} from '@/pages';

import { removeTokens, tokenIsValid } from '@/utils/auth';

import {
  getUserAvailableOrganizationUuids,
  getUserInfo,
} from '@/redux/reducers/auth/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';
import { RootState } from '@/redux/stores/store';

import './App.scss';

import { SupersetPage } from './pages/SupersetPage/SupersetPage';

function App() {
  const navigate = useNavigate();
  const [openUsers, setOpenUsers] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { login, email, organization } = useAppSelector(
    (state: RootState) => state.auth.userInfo,
  );
  const isLoadingToken = useAppSelector(
    (state: RootState) => state.auth.isLoading,
  );
  const isLoading = isLoadingToken;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const checkToken = useCallback(async () => {
    if (!tokenIsValid()) {
      removeTokens();
      setIsLoggedIn(false);
      navigate('/login');
    } else if (!login || !email || !organization) {
      dispatch(getUserInfo());
      dispatch(getUserAvailableOrganizationUuids());
    } else {
      setIsLoggedIn(true);
    }
  }, [navigate, login, email, organization, dispatch]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  const handleLogout = () => {
    dispatch({ type: 'USER_SIGNOUT' });
    removeTokens();
    navigate('/login');
  };

  return (
    <div className="App">
      <Layout
        isLoggedIn={isLoggedIn}
        handleClickUser={() => setOpenUsers(true)}
      >
        <Routes>
          <Route
            path="/login"
            element={<LoginPage isLoggedIn={isLoggedIn} />}
          />
          <Route path="/" element={<SupersetPage />} />
          <Route path="/action-list" element={<ActionListPage />} />
          <Route path="/sustainable-it-audit" element={<SustainableITPage />} />
          <Route
            path="/sustainable-it-audit/:familyID"
            element={<FamilyDetailsPage />}
          />
          <Route
            path="/sustainable-it-audit/:familyID/:questionID"
            element={<QuestionDetailsPage />}
          />
          <Route
            path="/sustainable-it-audit/results"
            element={<ResultDetailsPage />}
          />
          <Route path="/impact-scoring" element={<SupersetPage />} />
          <Route
            path="/learning-playground"
            element={<LearningPlaygroundPage />}
          />
          <Route
            path="/learning-playground/:quizID/:questionID"
            element={<QuizQuestionPage />}
          />
          <Route
            path="/learning-playground/result/:quizID"
            element={<QuizResultPage />}
          />
          <Route path="/carbon-how-to" element={<IFramePage />} />
          <Route path="/carbon-coach" element={<CarbonPage />} />
          <Route
            path="/carbon-coach/new-report"
            element={<CarbonUploadPage />}
          />
          <Route
            path="/carbon-coach/overview/:reportID"
            element={<CarbonReportDetailPage />}
          />
          <Route path="/physical-carbon-calculator" element={<IFramePage />} />
          <Route path="/carbon-accounting" element={<SupersetPage />} />
          <Route path="/expense" element={<SupersetPage />} />
          <Route path="/asset-carbon" element={<SupersetPage />} />
          <Route path="/transport-carbon" element={<SupersetPage />} />
          <Route path="/cix" element={<IFramePage />} />
          <Route path="/tfnd" element={<IFramePage />} />
          <Route path="/planetary-limits" element={<IFramePage />} />
          <Route path="/regulation-analysis" element={<IFramePage />} />
          <Route path="/life-cycle-analysis" element={<IFramePage />} />
          <Route path="/sustainability" element={<IFramePage />} />
          <Route path="/sdg-explained" element={<IFramePage />} />
          <Route path="/ghg-protocol-report" element={<SupersetPage />} />
          <Route path="/awareness-dashboard" element={<SupersetPage />} />
          <Route path="/time-line" element={<SupersetPage />} />
          <Route path="/marketplace" element={<IFramePage />} />
          <Route path="/delivery-playbook-kpi-update" element={<KPIUpdatePage />} />
          <Route path="/waste" element={<SupersetPage />} />
          <Route path="/financial" element={<SupersetPage />} />
          <Route path="/gender-study" element={<SupersetPage />} />
          <Route path="/esrs-report" element={<SupersetPage />} />
          <Route path="/esrs-assessment" element={<IFramePage />} />

          <Route path="*" element={<WIPPage />} />
        </Routes>
        {isLoading && <LoaderAnimation />}
        <UserMenuContainer
          onClickAction={() => setOpenUsers(false)}
          open={openUsers}
          handleLogout={handleLogout}
        />
      </Layout>
    </div>
  );
}

export default App;
