import { AuthState } from 'SustainablePlatform';

export type Token = {
  AccessToken: AccessToken;
  IDToken: IdToken;
  RefreshToken: RefreshToken;
};

export type AccessToken = {
  jwtToken: string;
  payload: { [key: string]: any };
};

export type IdToken = {
  jwtToken: string;
  payload: { [key: string]: any };
};

export type RefreshToken = {
  token: string;
};

export type AvailableOrganization = {
  organizationUuid: string;
  organizationName: string;
};

export const defaultAuthState: AuthState = {
  userInfo: {
    email: '',
    organization: '',
    organizationUuid: '',
    login: '',
    supersetGuestToken: '',
    supersetCsrfToken: '',
    supersetAccessToken: '',
    supersetDomain: '',
    supersetEmbeddedDashboards: {
      asset_carbon_dashboard_id: '',
      asset_transport_carbon_dashboard_id: '',
      awareness_dashboard_id: '',
      carbon_accounting_dashboard_id: '',
      delivery_kpi_dashboard_id: '',
      fitnet_dashboard_id: '',
      gender_study_kpi_dashboard_id: '',
      impact_score_dashboard_id: '',
      time_line_dashboard_id: '',
      triple_bottom_line_dashboard_id: '',
      waste_dashboard_id: '',
      esrs_dashboard_id: '',
    },
  },
  isLoggedIn: false,
  isLoading: false,
  isError: false,
  errorMsg: null,
  availableOrganizations: [],
};
