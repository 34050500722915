export const SupersetDashboardMap: Record<string, string> = {
  '': 'triple_bottom_line_dashboard_id',
  'asset-carbon': 'asset_carbon_dashboard_id',
  'awareness-dashboard': 'awareness_dashboard_id',
  'carbon-accounting': 'carbon_accounting_dashboard_id',
  "expense": 'carbon_accounting_dashboard_id',
  "financial": 'fitnet_dashboard_id',
  "gender-study": 'gender_study_kpi_dashboard_id',
  'ghg-protocol-report': 'delivery_kpi_dashboard_id',
  'impact-scoring': 'impact_score_dashboard_id',
  'time-line': 'time_line_dashboard_id',
  'transport-carbon': 'asset_transport_carbon_dashboard_id',
  'waste': 'waste_dashboard_id',
  'esrs-report': 'esrs_dashboard_id'
};
