import {
  AssessmentOutlined,
  Co2Outlined,
  DashboardCustomizeOutlined,
  DirectionsRunOutlined,
  LightbulbOutlined,
  MonetizationOnOutlined,
  NaturePeopleOutlined,
  QuizOutlined,
  RecyclingOutlined,
  SpokeOutlined,
  StorefrontOutlined,
  SvgIconComponent,
  TimelineOutlined,
} from '@mui/icons-material';

export interface SideMenuItemProps {
  text: string;
  translationKey: string;
  icon: SvgIconComponent;
  url?: string;
  children?: SideMenuItemProps[];
  visible?: boolean;
}

export const SideMenuItems: SideMenuItemProps[] = [
  {
    text: 'Triple Bottom Line',
    translationKey: 'menu.tripleBottomLine',
    icon: SpokeOutlined,
    url: '',
  },
  {
    text: 'Time Line',
    translationKey: 'menu.timeLine',
    icon: TimelineOutlined,
    url: 'time-line',
  },
  {
    text: 'Carbon',
    translationKey: 'menu.carbon',
    icon: Co2Outlined,
    children: [
      {
        text: 'Carbon How To',
        translationKey: 'menu.carbonHowTo',
        icon: AssessmentOutlined,
        url: 'carbon-how-to',
      },
      {
        text: 'Carbon Accounting',
        translationKey: 'menu.carbonAccounting',
        icon: MonetizationOnOutlined,
        url: 'carbon-accounting',
      },
      {
        text: 'Expense',
        translationKey: 'menu.expense',
        icon: MonetizationOnOutlined,
        url: 'expense',
      },
      {
        text: 'Asset Carbon',
        translationKey: 'menu.assetCarbon',
        icon: MonetizationOnOutlined,
        url: 'asset-carbon',
      },
      {
        text: 'Transport Carbon',
        translationKey: 'menu.transportCarbon',
        icon: MonetizationOnOutlined,
        url: 'transport-carbon',
      },
      {
        text: 'Digital Carbon Assessment',
        translationKey: 'menu.digitalCarbonAssessment',
        icon: AssessmentOutlined,
        url: 'carbon-coach',
      },
      {
        text: 'Waste',
        translationKey: 'menu.waste',
        icon: AssessmentOutlined,
        url: 'waste',
      },
      {
        text: 'CIX',
        translationKey: 'menu.cix',
        icon: AssessmentOutlined,
        url: 'cix',
      },
    ],
  },
  {
    text: 'Environment',
    translationKey: 'menu.environment',
    icon: NaturePeopleOutlined,
    children: [
      {
        text: 'TFND',
        translationKey: 'menu.tfnd',
        icon: AssessmentOutlined,
        url: 'tfnd',
      },
      {
        text: 'Planetary Limits',
        translationKey: 'menu.planetaryLimits',
        icon: AssessmentOutlined,
        url: 'planetary-limits',
      },
      {
        text: 'Regulation Analysis',
        translationKey: 'menu.regulationAnalysis',
        icon: AssessmentOutlined,
        url: 'regulation-analysis',
      },
      {
        text: 'Life Cycle analysis',
        translationKey: 'menu.lifeCycleAnalysis',
        icon: AssessmentOutlined,
        url: 'life-cycle-analysis',
      },
      {
        text: 'Waste',
        translationKey: 'menu.waste',
        icon: AssessmentOutlined,
        url: 'waste',
      },
    ],
  },
  {
    text: 'Sustainability',
    translationKey: 'menu.sustainability',
    icon: RecyclingOutlined,
    children: [
      {
        text: 'Sustainability',
        translationKey: 'menu.sustainability',
        icon: AssessmentOutlined,
        url: 'sustainability',
      },
      {
        text: 'Sustainable Development Goals',
        translationKey: 'menu.sustainableDevelopmentGoals',
        icon: AssessmentOutlined,
        url: 'sdg-explained',
      },
      {
        text: 'GR491 Assessment',
        translationKey: 'menu.gr491Assessment',
        icon: AssessmentOutlined,
        url: 'sustainable-it-audit',
      },
      {
        text: 'Impact Scoring',
        translationKey: 'menu.impactScoring',
        icon: AssessmentOutlined,
        url: 'impact-scoring',
      },
      {
        text: 'ESRS report',
        translationKey: 'menu.esrsReport',
        icon: AssessmentOutlined,
        url: 'esrs-report',
      },
      {
        text: 'ESRS assessment',
        translationKey: 'menu.esrsAssessment',
        icon: AssessmentOutlined,
        url: 'esrs-assessment',
      },
    ],
  },
  {
    text: 'Profit',
    translationKey: 'menu.profit',
    icon: MonetizationOnOutlined,
    children: [
      {
        text: 'KPI',
        translationKey: 'menu.KPI',
        icon: AssessmentOutlined,
        url: 'ghg-protocol-report',
      },
      {
        text: 'KPIs Update Portal',
        translationKey: 'menu.deliveryPlaybookUpdatePortal',
        icon: AssessmentOutlined,
        url: 'delivery-playbook-kpi-update',
      },
      {
        text: 'Financial',
        translationKey: 'menu.financial',
        icon: AssessmentOutlined,
        url: 'financial',
      },
    ],
  },
  {
    text: 'Awareness',
    translationKey: 'menu.awareness',
    icon: LightbulbOutlined,
    children: [
      {
        text: 'Awareness Dashboard',
        translationKey: 'menu.awarenessDashboard',
        icon: DashboardCustomizeOutlined,
        url: 'awareness-dashboard',
      },
      {
        text: 'Quizzes',
        translationKey: 'menu.quizzes',
        icon: QuizOutlined,
        url: 'learning-playground',
      },
      {
        text: 'ESG Governance',
        translationKey: 'menu.genderStudy',
        icon: QuizOutlined,
        url: 'gender-study',
      },
    ],
  },
  {
    text: 'Action',
    translationKey: 'menu.action',
    icon: DirectionsRunOutlined,
    url: 'action-list',
  },
  {
    text: 'Marketplace',
    translationKey: 'menu.marketplace',
    icon: StorefrontOutlined,
    url: 'marketplace',
  },
];
